<template>
  <div class="card">
    <div class="card-body">
      <div style="margin: 20px">
        <a-button
          type="primary"
          @click="generatePdf"
          :loading="printLoading"
          :disabled="printLoading"
        >
          <a-icon type="file-pdf" v-if="!printLoading" @click="generatePdf" />
          {{ $t("action.imprimerTab") }}
        </a-button>

        <a-range-picker
          style="margin-left: 20px"
          :placeholder="[$t('paiement.dateDebut'), $t('paiement.dateFin')]"
          format="DD-MM-YYYY"
          v-model="dateRangeData"
          :mode="mode"
          @change="
            (val) => {
              handleDateRange(val);
            }
          "
        />
      </div>
      <div>
        <a-table
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
            onSelectAll: onSelectAll,
          }"
          @change="tableChanged"
          rowKey="_id"
          :pagination="true"
          :data-source="data"
          :columns="columns"
          :loading="tableLoading"
          :scroll="{ x: 'max-content' }"
        >
          <template slot="serial" slot-scope="text, record">
            <a-tag
              color="red"
              v-if="
                record.status == 'cancelled' &&
                !(record.cancellation && record.cancellation.type == 'Avoir')
              "
              >{{ text }}</a-tag
            >
            <a-tag
              color="orange"
              v-else-if="
                record.cancellation && record.cancellation.type == 'Avoir'
              "
              >{{ text }}</a-tag
            >
            <p v-else>{{ text }}</p>
          </template>
          <template slot="paymentOnWhat" slot-scope="text, record">
            <div class="d-inline">
              <a-tag color="blue" v-if="record.info.onInscription">{{
                $t("inscription.inscription")
              }}</a-tag>
              <div v-if="record.info.months">
                <a-tag
                  color="blue"
                  v-for="(value, key) in record.info.months"
                  :key="key"
                  >{{ frMonths(key) }}</a-tag
                >
              </div>
            </div>
          </template>
          <template slot="modePaiement" slot-scope="text, record">
            <a-button
              icon="search"
              type="primary"
              block
              v-if="record.cheque || text === 'Chèque et espéce'"
              @click="chequeDetailsModal(record)"
            >
              {{ text }}
            </a-button>
            <div v-else>{{ text }}</div>
          </template>
          <template slot="date" slot-scope="text">
            {{ moment(text).format("DD/MM/YYYY") }}
          </template>
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="`${$t('paiement.chercher')} ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
              >{{ $t("scolarite.recherche") }}</a-button
            >
            <a-button
              size="small"
              style="width: 90px"
              @click="() => handleReset(clearFilters)"
              >{{ $t("action.reinitialiser") }}</a-button
            >
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>{{ text }}</template>
          </template>
          <a-tag color="#2db7f5" slot="userName" slot-scope="text">
            {{ text ? text : "--" }}
          </a-tag>
          <a-button
            slot="print"
            type="primary"
            slot-scope="text, record"
            @click="() => print(record)"
          >
            <a-icon type="file-pdf" />{{ $t("action.imprimer") }}
          </a-button>
        </a-table>
      </div>
    </div>
    <a-modal
      v-model="chequeVisible"
      :title="$t('recette.detailsCheque')"
      @ok="handleOk"
    >
      <div v-if="activeCheque.montantCheque || activeCheque.montantEspece">
        <a-descriptions>
          <a-descriptions-item span="2">
            <template v-slot:label>
              <span class="ant-descriptions-item-label">Montant chèque :</span>
            </template>
            {{ activeCheque.montantCheque }}
          </a-descriptions-item>
          <a-descriptions-item span="3">
            <template v-slot:label>
              <span class="ant-descriptions-item-label">Montant espèce :</span>
            </template>
            {{ activeCheque.montantEspece }}
          </a-descriptions-item>
        </a-descriptions>
        <a-divider></a-divider>
      </div>

      <a-descriptions>
        <a-descriptions-item span="2">
          <template v-slot:label>
            <span class="ant-descriptions-item-label">{{
              $t("recette.numero")
            }}</span>
          </template>
          {{ activeCheque.num }}
        </a-descriptions-item>
        <a-descriptions-item span="3">
          <template v-slot:label>
            <span class="ant-descriptions-item-label">{{
              $t("recette.titulaire")
            }}</span>
          </template>
          {{ activeCheque.titulaire }}
        </a-descriptions-item>
        <a-descriptions-item span="4">
          <template v-slot:label>
            <span class="ant-descriptions-item-label">{{
              $t("recette.banque")
            }}</span>
          </template>
          <a-tag color="blue">
            {{ activeCheque.bank }}
          </a-tag>
        </a-descriptions-item>

        <a-descriptions-item span="4">
          <template v-slot:label>
            <span class="ant-descriptions-item-label">{{
              $t("recette.dateEcheance")
            }}</span>
          </template>
          {{ moment(activeCheque.dateEchance).format("DD/MM/YYYY") }}
        </a-descriptions-item>
      </a-descriptions>
      <a-alert
        :message="moment(activeCheque.dateEchance).fromNow()"
        type="info"
        :close-text="$t('recette.fermer')"
      />
    </a-modal>
  </div>
</template>

<script>
/* eslint-disable */
import $ from "jquery";
import axios from "axios";
import JsPDF from "jspdf";
import "jspdf-autotable";
import writtenNumber from "written-number";
import { mapState } from "vuex";
import apiClient from "@/services/axios";
import Vue from "vue";
import VueRouter from "vue-router";
import moment from "moment";
import { type } from "os";
import JsonCsv from "vue-json-csv";
Vue.use(VueRouter);

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const dateRange = (startDate, endDate) => {
  var start = startDate.split("-");
  var end = endDate.split("-");
  var startYear = parseInt(start[0]);
  var endYear = parseInt(end[0]);
  var dates = [];

  for (var i = startYear; i <= endYear; i++) {
    var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
    var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
    for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      var month = j + 1;
      var displayMonth = month < 10 ? "0" + month : month;
      dates.push([i, displayMonth, "01"].join("-"));
    }
  }
  return dates;
};
const getStatus = (entry) => {
  if (!entry.inscription) return "impayed";
  else {
    if (entry.once == false && entry.months)
      for (const [key, value] of Object.entries(entry.months)) {
        if (!value) return "impayed";
      }
    return "payed";
  }
};

export default {
  props: {
    studentId: {
      required: true,
    },
  },
  computed: {
    ...mapState(["settings"]),
    csvToExport() {
      const data = [...this.filtredTable];
      return data.map((item) => {
        let elems = [];
        if (item.info.months)
          for (const key of Object.keys(item.info.months)) {
            elems.push(this.frMonths(key));
          }

        return {
          serial: item.serial,
          nomClient: item.nomClient,
          typeReglement: item.typeReglement,
          paiementSur: elems,
          modePaiement: item.modePaiement,
          montantHT: item.montantHT,
          montantTTC: item.montantTTC,
          datePaiement: moment(item.date).format("DD/MM/YYYY"),
        };
      });
    },
  },
  components: {
    JsonCsv,
  },
  async created() {
    this.$gtag.pageview({
      page_path: this.$route.path,
      page_location: window.location.href,
      page_title: document.title + " - Liste de réglements",
    });

    await apiClient
      .post("/parents/filter", {
        query: {
          students: this.studentId,
        },
        aggregation: {
          $project: {
            father: 1,
            mother: 1,
          },
        },
      })
      .then((res) => (this.parents = res.data))
      .catch();
    await apiClient
      .post("/money/activityreceipt/filter", {
        query: {},
        aggregation: [
          {
            $lookup: {
              from: "activityfees",
              /*  localField: "activityFees",
              foreignField: "_id", */
              let: { activityId: "$activityFees" },
              pipeline: [
                {
                  $match: {
                    $expr: {
                      $and: [
                        { $eq: ["$_id", "$$activityId"] },
                        {
                          $eq: ["$student", { $toObjectId: this.studentId }],
                        },
                        // { $gte: [ "$instock", "$$order_qty" ] }
                      ],
                    },
                  },
                },
              ],
              as: "activityfees",
            },
          },
          {
            $unwind: { path: "$activityfees" },
          },
          {
            $lookup: {
              from: "students",
              localField: "activityfees.student",
              foreignField: "_id",
              as: "student",
            },
          },
          {
            $lookup: {
              from: "activities",
              localField: "activityfees.activity",
              foreignField: "_id",
              as: "activity",
            },
          },
          {
            $lookup: {
              from: "receipttypes",
              localField: "receiptType",
              foreignField: "_id",
              as: "receiptType",
            },
          },

          {
            $lookup: {
              from: "cheques",
              localField: "cheque",
              foreignField: "_id",
              as: "cheque",
            },
          },
          {
            $project: {
              _id: 1,
              date: 1,
              serial: 1,
              months: 1,
              onBillReduction: 1,
              onInscription: 1,
              student: {
                _id: 1,
                firstName: 1,
                lastName: 1,
                parent: 1,
              },
              activity: {
                _id: 1,
                designation: 1,
                monthly: 1,
                inscription: 1,
              },
              activityfees: {
                _id: 1,
                reduction: 1,
                inscription: 1,
                months: 1,
              },
              receiptType: { name: 1 },
              cheque: { $arrayElemAt: ["$cheque", 0] },
              montantCheque: 1,
              montantEspece: 1,
              userName: 1,
              status: 1,
              cancellation: 1,
            },
          },
        ],
      })
      .then((res) => {
        res.data.map((elem) => {
          this.data.push(
            this.activityFormater({
              ...elem,
              activity: elem.activity[0],
              activityfees: elem.activityfees,
              receiptType: elem.receiptType[0],
              student: elem.student[0],
            })
          );
        });
      })
      .catch();
    await apiClient
      .post("/money/activitybill/filter", {
        query: {},
        aggregation: [
          {
            $lookup: {
              from: "activityfees",
              /* localField: "activityFees",
              foreignField: "_id", */
              let: { activityId: "$activityFees" },
              pipeline: [
                {
                  $match: {
                    $expr: {
                      $and: [
                        { $eq: ["$_id", "$$activityId"] },
                        {
                          $eq: ["$student", { $toObjectId: this.studentId }],
                        },
                        // { $gte: [ "$instock", "$$order_qty" ] }
                      ],
                    },
                  },
                },
              ],
              as: "activityfees",
            },
          },
          {
            $unwind: { path: "$activityfees" },
          },
          {
            $lookup: {
              from: "students",
              localField: "activityfees.student",
              foreignField: "_id",
              as: "student",
            },
          },
          {
            $lookup: {
              from: "activities",
              localField: "activityfees.activity",
              foreignField: "_id",
              // pipeline: [
              //   {
              //     $match: {
              //       $expr: {
              //         $and: [
              //           {
              //             $eq: ["$_id", "$activityfees.activity"],
              //             $eq: ["$schoolarYear", { $toObjectId: this.$store.getters.activeSchoolarYearGetter }],
              //           },
              //           /*    { $eq: ["$inscription", "true"] }, */
              //         ],
              //       },
              //     },
              //   },
              //   /*   { $project: { _id: 1, student: 1, activity: 1 } }, */
              // ],
              as: "activity",
            },
          },
          {
            $lookup: {
              from: "receipttypes",
              localField: "receiptType",
              foreignField: "_id",
              as: "receiptType",
            },
          },
          {
            $lookup: {
              from: "cheques",
              localField: "cheque",
              foreignField: "_id",
              as: "cheque",
            },
          },
          {
            $project: {
              _id: 1,
              date: 1,
              serial: 1,
              months: 1,
              onBillReduction: 1,
              onInscription: 1,
              student: {
                _id: 1,
                firstName: 1,
                lastName: 1,
                parent: 1,
              },
              activity: {
                _id: 1,
                designation: 1,
                monthly: 1,
                inscription: 1,
              },
              activityfees: {
                _id: 1,
                reduction: 1,
                inscription: 1,
                months: 1,
              },
              receiptType: { name: 1 },
              cheque: { $arrayElemAt: ["$cheque", 0] },
              montantCheque: 1,
              montantEspece: 1,
              userName: 1,
              status: 1,
              cancellation: 1,
            },
          },
        ],
      })
      .then((res) => {
        res.data.map((elem) => {
          this.data.push(
            this.activityFormater(
              {
                ...elem,
                activity: elem.activity[0],
                activityfees: elem.activityfees,
                receiptType: elem.receiptType[0],
                student: elem.student[0],
              },
              true
            )
          );
        });
      })
      .catch();
    await apiClient
      .post("/money/classroomreceipt/filter", {
        query: {},
        aggregation: [
          {
            $lookup: {
              from: "classroompayments",
              /*   localField: "classRoomPayment",
              foreignField: "_id", */
              let: { classRoomPayment: "$classRoomPayment" },
              pipeline: [
                {
                  $match: {
                    $expr: {
                      $and: [
                        { $eq: ["$_id", "$$classRoomPayment"] },
                        {
                          $eq: ["$student", { $toObjectId: this.studentId }],
                        },
                        /*    { $eq: ["$inscription", "true"] }, */
                      ],
                    },
                  },
                },
                /*   { $project: { _id: 1, student: 1, activity: 1 } }, */
              ],
              as: "classRoomPayment",
            },
          },
          {
            $unwind: { path: "$classRoomPayment" },
          },
          {
            $lookup: {
              from: "students",
              localField: "classRoomPayment.student",
              foreignField: "_id",
              as: "student",
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classRoomPayment.classRoom",
              foreignField: "_id",
              as: "classRoom",
            },
          },
          {
            $lookup: {
              from: "receipttypes",
              localField: "receiptType",
              foreignField: "_id",
              as: "receiptType",
            },
          },
          {
            $lookup: {
              from: "cheques",
              localField: "cheque",
              foreignField: "_id",
              as: "cheque",
            },
          },
          {
            $project: {
              _id: 1,
              date: 1,
              serial: 1,
              months: 1,
              onBillReduction: 1,
              onInscription: 1,
              student: {
                _id: 1,
                firstName: 1,
                lastName: 1,
                parent: 1,
              },
              classRoom: {
                _id: 1,
                name: 1,
                monthly: 1,
                inscription: 1,
              },
              classRoomPayment: {
                _id: 1,
                reduction: 1,
                inscription: 1,
                months: 1,
              },
              receiptType: { name: 1 },
              cheque: { $arrayElemAt: ["$cheque", 0] },
              montantCheque: 1,
              montantEspece: 1,
              userName: 1,
              status: 1,
              cancellation: 1,
            },
          },
        ],
      })
      .then((res) => {
        res.data.map((elem) => {
          this.data.push(
            this.classRoomPFormater({
              ...elem,
              classRoom: elem.classRoom[0],
              classRoomPayment: elem.classRoomPayment,
              receiptType: elem.receiptType[0],
              student: elem.student[0],
            })
          );
        });
      })
      .catch();
    await apiClient
      .post("/money/classroombill/filter", {
        query: {},
        aggregation: [
          {
            $lookup: {
              from: "classroompayments",
              /*  localField: "classRoomPayment",
              foreignField: "_id", */
              let: { classRoomPayment: "$classRoomPayment" },
              pipeline: [
                {
                  $match: {
                    $expr: {
                      $and: [
                        { $eq: ["$_id", "$$classRoomPayment"] },
                        {
                          $eq: ["$student", { $toObjectId: this.studentId }],
                        },
                        /*    { $eq: ["$inscription", "true"] }, */
                      ],
                    },
                  },
                },
                /*   { $project: { _id: 1, student: 1, activity: 1 } }, */
              ],
              as: "classRoomPayment",
            },
          },
          {
            $unwind: { path: "$classRoomPayment" },
          },
          {
            $lookup: {
              from: "students",
              localField: "classRoomPayment.student",
              foreignField: "_id",
              as: "student",
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classRoomPayment.classRoom",
              foreignField: "_id",
              as: "classRoom",
            },
          },
          {
            $lookup: {
              from: "receipttypes",
              localField: "receiptType",
              foreignField: "_id",
              as: "receiptType",
            },
          },
          {
            $lookup: {
              from: "cheques",
              localField: "cheque",
              foreignField: "_id",
              as: "cheque",
            },
          },
          {
            $project: {
              _id: 1,
              date: 1,
              serial: 1,
              months: 1,
              onBillReduction: 1,
              onInscription: 1,
              student: {
                _id: 1,
                firstName: 1,
                lastName: 1,
                parent: 1,
              },
              classRoom: {
                _id: 1,
                name: 1,
                monthly: 1,
                inscription: 1,
              },
              classRoomPayment: {
                _id: 1,
                reduction: 1,
                inscription: 1,
                months: 1,
              },
              receiptType: { name: 1 },
              cheque: { $arrayElemAt: ["$cheque", 0] },
              montantCheque: 1,
              montantEspece: 1,
              userName: 1,
              status: 1,
              cancellation: 1,
            },
          },
        ],
      })
      .then((res) => {
        res.data.map((elem) => {
          try {
            this.data.push(
              this.classRoomPFormater(
                {
                  ...elem,
                  classRoom: elem.classRoom[0],
                  classRoomPayment: elem.classRoomPayment,
                  receiptType: elem.receiptType[0],
                  student: elem.student[0],
                  onBillReduction: elem.onBillReduction,
                },
                true
              )
            );
          } catch {
            return;
          }
        });
      })
      .catch();
    this.cacheData = this.data.map((item) => ({ ...item }));
    this.rowData = this.data.map((item) => ({ ...item }));
    this.tableLoading = false;
    /* this.data = this.data.filter((item) => item.student._id == this.studentId); */
    this.filtredTable = this.data;
    this.selectedRowKeys = this.filtredTable.map((e) => e._id);
    this.filtredTableCache = this.filtredTable;
  },

  data() {
    return {
      dateRangeData: ["", ""],
      mode: ["date", "date"],
      fields: [
        "serial",
        "nomClient",
        "typeReglement",
        "paiementSur",
        "modePaiement",
        "montantHT",
        "montantTTC",
        "datePaiement",
      ],
      editingKey: "",
      FISCAL: parseFloat(process.env.VUE_APP_FISCAL),
      TVA: parseFloat(process.env.VUE_APP_TVA),
      rowData: [],
      tableLoading: true,
      parents: [],
      visibleParentFile: false,
      data: [],
      chequeVisible: false,
      activeCheque: {},
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      targetKeys: [],
      indeterminate: true,
      columns: [
        //numéro de série
        {
          title: this.$t("recette.numSerie"),
          dataIndex: "serial",
          key: "serial",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "serial",
          },
          onFilter: (value, record) =>
            record.serial.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.nom"),
          dataIndex: "nomClient",
          key: "nomClient",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "nomClient",
          },
          onFilter: (value, record) =>
            record.nomClient.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.typeReglement"),
          dataIndex: "typeReglement",
          key: "typeReglement",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "typeReglement",
          },
          onFilter: (value, record) =>
            record.typeReglement.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.paiementQuoi"),
          dataIndex: "paymentOnWhat",
          key: "paymentOnWhat",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "paymentOnWhat",
          },
          onFilter: (value, record) => {
            let result = false;
            try {
              const { info } = record;
              let elems = [];
              if (info.onInscription) elems.push("inscription");
              if (info.months)
                for (const key of Object.keys(info.months)) {
                  elems.push(this.frMonths(key));
                }
              for (const elem of elems) {
                if (elem.toLowerCase().includes(value.toLowerCase()))
                  result = true;
              }
            } catch {
              result = true;
            }
            return result;
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.modePaiement"),
          dataIndex: "modePaiement",
          key: "modePaiement",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "modePaiement",
          },
          onFilter: (value, record) =>
            record.modePaiement.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.montantHTVA"),
          dataIndex: "montantHT",
          key: "montantHT",
          sorter: (a, b) => a.montantHT - b.montantHT,
        },
        {
          title: this.$t("recette.montantTTC"),
          dataIndex: "montantTTC",
          key: "montantTTC",
          sorter: (a, b) => a.montantTTC - b.montantTTC,
        },
        {
          title: this.$t("recette.datePaiement"),
          dataIndex: "date",
          key: "date",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "date",
          },
          onFilter: (value, record) =>
            moment(record.date).format("DD/MM/YYYY").includes(value),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.doneBy"),
          dataIndex: "userName",
          key: "userName",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "userName",
          },
          onFilter: (value, record) =>
            record.userName.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.impression"),
          dataIndex: "print",
          key: "print",
          fixed: "right",
          scopedSlots: {
            customRender: "print",
          },
        },
      ],
      filtredTable: [],
      filtredTableCache: [],
      printLoading: false,
      selectedRowKeys: [], // Check here to configure the default column
    };
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    onSelectAll(selected, selectedRows, changeRows) {
      this.selectedRowKeys = selected
        ? this.filtredTable.map((e) => e._id)
        : [];
    },
    handleDateRange(value) {
      if (value.length == 2) {
        const start = moment(value[0]);
        const end = moment(value[1]);

        this.data = this.rowData.filter((elem) => {
          return moment(elem.date).isBetween(start, end, "days", "[]");
        });

        this.filtredTable = this.filtredTableCache.filter((elem) => {
          return moment(elem.date).isBetween(start, end, "days", "[]");
        });
      } else {
        this.data = this.rowData;
        this.filtredTable = this.filtredTableCache;
      }
    },
    chequeDetailsModal(record) {
      let recordData = JSON.parse(JSON.stringify(record));

      this.activeCheque = recordData.cheque;
      this.activeCheque.montantCheque = recordData.montantCheque;
      this.activeCheque.montantEspece = recordData.montantEspece;
      this.chequeVisible = true;
    },
    handleOk(e) {
      this.chequeVisible = false;
    },
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
      this.filtredTableCache = extra.currentDataSource;
    },
    moment,
    frMonths(s) {
      const months = {};
      const monthsArr = moment.months();
      for (let i = 0; i < monthsArr.length; i++) months[i + 1] = monthsArr[i];

      return months[parseInt(s.split("-")[1])];
    },
    classRoomPFormater(paymentData, bill = false) {
      const reduction = {
        inscription: paymentData.classRoomPayment.reduction.inscription,
        monthly: paymentData.classRoomPayment.reduction.monthly,
      };
      let money = 0;
      if (paymentData.onInscription) {
        const inscriptionFees = parseInt(paymentData.classRoom.inscription);
        money =
          inscriptionFees - (inscriptionFees * reduction.inscription) / 100;
      }
      if (paymentData.months) {
        const nbMonth = Object.keys(paymentData.months).length;
        const monthFees = nbMonth * paymentData.classRoom.monthly;
        money = money + monthFees - (monthFees * reduction.monthly) / 100;
      }
      const onBillReduction = paymentData.onBillReduction
        ? paymentData.onBillReduction
        : 0;
      money = money - (money * onBillReduction) / 100;
      const parent = this.parents.filter(
        (e) => e._id == paymentData.student.parent
      )[0];
      const id = paymentData._id;
      let serial = paymentData.serial;
      if (paymentData.status == "cancelled") {
        if (
          paymentData.cancellation &&
          paymentData.cancellation.type == "Avoir"
        )
          serial = serial + " Avoir";
        else serial = serial + " Annulé(e)";
      }
      let data = {
        userName: paymentData.userName ? paymentData.userName : "--",
        status: paymentData.status,
        onBillReduction: onBillReduction,
        serial: serial,
        url: bill ? "/bills/classroom/" + id : "/receipt/classroom/" + id,
        _id: id,
        nomClient: parent.father.firstName + " " + parent.father.lastName,
        classRoom: paymentData.classRoom,
        student: paymentData.student,
        typeReglement: "Scolarité/" + paymentData.classRoom.name,
        modePaiement: paymentData.receiptType.name,
        montantHT: money - money * this.TVA,
        montantTTC: money + (bill ? this.FISCAL : 0),
        classRoomPayment: paymentData.classRoomPayment,
        bill: bill,
        cheque: paymentData.cheque ? paymentData.cheque : null,
        montantEspece: paymentData.montantEspece
          ? paymentData.montantEspece
          : null,
        montantCheque: paymentData.montantCheque
          ? paymentData.montantCheque
          : null,
        date: paymentData.date,
        info: {
          onInscription: Boolean(paymentData.onInscription),
          months: paymentData.months,
        },
        cancellation: paymentData.cancellation,
      };
      return data;
    },
    activityFormater(paymentData, bill = false) {
      const reduction = {
        inscription: paymentData.activityfees.reduction.inscription,
        monthly: paymentData.activityfees.reduction.monthly,
      };
      let money = 0;
      if (paymentData.onInscription) {
        const inscriptionFees = parseInt(paymentData.activity.inscription);
        money =
          inscriptionFees - (inscriptionFees * reduction.inscription) / 100;
      }
      if (paymentData.months) {
        const nbMonth = Object.keys(paymentData.months).length;
        const monthFees = nbMonth * paymentData.activity.monthly;
        money = money + monthFees - (monthFees * reduction.monthly) / 100;
      }
      const onBillReduction = paymentData.onBillReduction
        ? paymentData.onBillReduction
        : 0;
      money = money - (money * onBillReduction) / 100;
      const parent = this.parents.filter(
        (e) => e._id == paymentData.student.parent
      )[0];
      const id = paymentData._id;
      let serial = paymentData.serial;
      if (paymentData.status == "cancelled") {
        if (
          paymentData.cancellation &&
          paymentData.cancellation.type == "Avoir"
        )
          serial = serial + " Avoir";
        else serial = serial + " Annulé(e)";
      }
      let data = {
        userName: paymentData.userName ? paymentData.userName : "--",
        status: paymentData.status,
        serial,
        onBillReduction: onBillReduction,
        url: bill ? "/bills/activity/" + id : "/receipt/activity/" + id,
        _id: id,
        nomClient: parent.father.firstName + " " + parent.father.lastName,
        typeReglement: "Activité/" + paymentData.activity.designation,
        modePaiement: paymentData.receiptType.name,
        activity: paymentData.activity,
        student: paymentData.student,
        montantHT: money - money * this.TVA,
        montantTTC: money + (bill ? this.FISCAL : 0),
        activityfees: paymentData.activityfees,
        bill: bill,
        cheque: paymentData.cheque ? paymentData.cheque : null,
        montantEspece: paymentData.montantEspece
          ? paymentData.montantEspece
          : null,
        montantCheque: paymentData.montantCheque
          ? paymentData.montantCheque
          : null,
        date: paymentData.date,
        info: {
          onInscription: Boolean(paymentData.onInscription),
          months: paymentData.months,
        },
        cancellation: paymentData.cancellation,
      };
      return data;
    },

    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    edit(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    save(key) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.filter((item) => key === item.key)[0];
      const targetCache = newCacheData.filter((item) => key === item.key)[0];
      if (target && targetCache) {
        delete target.editable;
        this.data = newData;
        Object.assign(targetCache, target);
        this.cacheData = newCacheData;
      }
      this.editingKey = "";
    },
    cancel(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = "";
      if (target) {
        Object.assign(
          target,
          this.cacheData.filter((item) => key === item.key)[0]
        );
        delete target.editable;
        this.data = newData;
      }
    },
    onDelete(key) {
      const dataSource = [...this.data];
      this.data = dataSource.filter((item) => item.key !== key);
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    print(record) {
      this.$gtag.event("Imp one reglement", {
        event_category: "Impression PDF",
        event_label: "ProfileEleve:listeReglement",
        value: 1,
      });

      const tva = 0.07;
      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();

      var titre = record.bill
        ? "Facture N. " + record.serial.split(":")[1]
        : "Reçue N. " + record.serial.split("/")[1];
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(15);
      doc.text(85, 22, titre);
      doc.setFontSize(12);

      doc.text(15, 35, "Nom et Prénom : " + record.nomClient);
      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 15, 5, 18, 18);
      doc.setFontSize(8);
      doc.text(170, 15, date.toLocaleDateString("fr-FR", options));
      var arr = [];
      const array = [];

      let reduction = {};
      let onBillReduction = record.onBillReduction
        ? record.onBillReduction / 100
        : 0;
      if (!record.activity)
        reduction = {
          inscription: record.classRoomPayment.reduction.inscription,
          monthly: record.classRoomPayment.reduction.monthly,
        };
      if (record.info.onInscription) {
        arr.push(record.student.lastName + " " + record.student.firstName);
        arr.push("Inscription ");
        arr.push(record.typeReglement);
        //record.activity?arr.push(record.activity.inscription):arr.push(record.classRoom.inscription);
        record.activity
          ? arr.push(
              record.bill === true
                ? record.activity.inscription -
                    record.activity.inscription * tva
                : record.activity.inscription -
                    (record.activity.inscription * reduction.inscription) / 100
            )
          : arr.push(
              record.bill
                ? record.classRoom.inscription -
                    record.classRoom.inscription * tva
                : record.classRoom.inscription -
                    (record.classRoom.inscription * reduction.inscription) / 100
            );
        //arr[arr.length - 1] -= arr[arr.length - 1] * onBillReduction;
        array.push(arr);
        arr = [];
      }
      if (record.info.months) {
        Object.keys(record.info.months).forEach((k) => {
          arr.push(record.student.lastName + " " + record.student.firstName);

          let paymentDate = new Date(k);
          let monthName = paymentDate.toLocaleString("fr-FR", {
            month: "long",
          });
          arr.push(monthName);
          arr.push(record.typeReglement);

          record.activity
            ? arr.push(
                record.bill === true
                  ? record.activity.monthly - record.activity.monthly * tva
                  : record.activity.monthly
              )
            : arr.push(
                record.bill
                  ? record.classRoom.monthly - record.classRoom.monthly * tva
                  : record.classRoom.monthly -
                      (record.classRoom.monthly * reduction.monthly) / 100
              );
          //arr[arr.length - 1] -= arr[arr.length - 1] * onBillReduction;

          array.push(arr);
          arr = [];
        });
      }
      let rowData = [
        record.montantHT,
        record.montantTTC - record.montantHT - this.FISCAL,
        this.FISCAL,
        record.montantTTC,
      ];
      //affichier reduction si existe
      if (onBillReduction) {
        arr.push({
          colSpan: 3,
          content: "Réduction",
          styles: { valign: "middle", halign: "center", fontStyle: "bold" },
        });
        arr.push({
          content: (onBillReduction * 100).toFixed(2) + "%",
          styles: { valign: "middle", halign: "center", fontStyle: "bold" },
        });
        array.push(arr);
        arr = [];
      }
      let rowDataText = ["Total HTVA", "TVA", "Timbre", "Total TTC"];
      for (let i = 0; i < 4; i++) {
        if (!record.bill) {
          i = 3;
          rowDataText[i] = "Total";
        }
        arr.push({
          colSpan: 3,
          content: rowDataText[i],
          styles: { valign: "middle", halign: "center", fontStyle: "bold" },
        });
        arr.push({
          content: parseFloat(rowData[i]).toFixed(2),
          styles: { valign: "middle", halign: "center", fontStyle: "bold" },
        });
        array.push(arr);
        arr = [];
      }

      doc.autoTable({
        styles: {
          lineWidth: 0.2,
          valign: "middle",
          halign: "center",
          fontStyle: "bold",
          font: "Amiri",
        },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 15 : 38,
        head: [["Stagiaire", "Frais", "Désignation", "Montant"]],
        body: array,
        foot: [
          [
            {
              colSpan: 4,
              content:
                "Le montant total est " +
                writtenNumber(record.montantTTC, { lang: "fr" }) +
                " DT",
              styles: { halign: "center" },
            },
          ],
        ],
      });
      doc.save(titre + ".pdf");
    },
    generatePdf() {
      this.$gtag.event("Imp listeReglement d'élève", {
        event_category: "Impression PDF",
        event_label: "ProfileEleve:listeReglement",
        value: 1,
      });

      this.printLoading = true;
      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();
      var titre = "Liste réglement";
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(15);
      doc.text(85, 22, titre);
      let basicInfo = this.filtredTable[0]
        ? this.filtredTable[0]
        : { nomClient: "--" };

      doc.setFontSize(12);

      doc.text(15, 35, "Nom complet : " + basicInfo.nomClient);

      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 15, 5, 18, 18);
      doc.setFontSize(8);
      doc.text(170, 15, date.toLocaleDateString("fr-FR", options));
      var arr = [];
      const array = [];
      let sommeTTC = 0;

      let selectedData = this.filtredTable.filter((e) =>
        this.selectedRowKeys.includes(e._id)
      );
      for (let i = 0; i < selectedData.length; i++) {
        //arr.push(this.filtredTable[i].serial);
        arr.push(selectedData[i].serial);
        arr.push(
          selectedData[i].student.lastName +
            " " +
            selectedData[i].student.firstName
        );
        arr.push(moment(selectedData[i].date).format("DD/MM/YYYY"));

        arr.push(selectedData[i].typeReglement);
        arr.push(selectedData[i].modePaiement);
        let reduction = selectedData[i].onBillReduction
          ? parseFloat(selectedData[i].onBillReduction).toFixed(2) + "%"
          : "--";
        arr.push(reduction);
        arr.push(selectedData[i].montantTTC.toFixed(2));
        sommeTTC += parseFloat(selectedData[i].montantTTC);
        array.push(arr);
        arr = [];
      }
      doc.autoTable({
        styles: { font: "Amiri" },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 15 : 38,
        head: [
          [
            //   "Num",
            "Num",
            "Nom enfant",
            "Date",
            "Type de réglement",
            "Mode de paiement",
            "Réduction",
            "Montant TTC",
          ],
        ],
        body: array,
        foot: [
          [
            {
              content: "Total",
              colSpan: 6,
              styles: {
                halign: "center",
              },
            },
            parseFloat(sommeTTC).toFixed(2),
          ],
        ],
      });
      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      //end page numbering
      doc.save("Liste_Réglement.pdf");

      this.printLoading = false;
    },
  },
};
</script>
<style scoped>
.ant-descriptions-item-label {
  font-weight: bold !important;
}
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
.editable-row-operations a {
  margin-right: 8px;
}
.switch {
  float: right;
  margin-right: 15px;
}
.bottom {
  margin-top: 7px;
}
label {
  width: 300px;
  line-height: 26px;
  margin-bottom: 20px;
}

a-input {
  height: 25px;
  width: 50%;
  margin-left: 50px;
}
.span {
  /* display: flex;  */
  flex-direction: row;
}
.space {
  margin-left: 200px;
}
.inp {
  margin-left: 0px;
}
.clearfix {
  float: right;
}
</style>
